<template>
  <div class="bg" data-testId="news-magazine-container">
    <div v-if="isLoading && !Boolean(postList.length)" class="container">
      <div class="row">
        <div :key="'loading_' + n" v-for="n in 3" class="col-lg-4 col-md-6">
          <div class="post">
            <div class="shimmer-wrapper">
              <div class="shine box"></div>
              <div class="shim-container">
                <div class="shine lines"></div>
                <div class="shine lines"></div>
                <div class="shine lines"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="Boolean(postList.length)" class="content-fix" data-testId="news-magazine-list">
      <div :key="item.year" v-for="item in postList">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="year-publication">{{ item.year }}</div>
            </div>
            <div :key="row.id" v-for="row in item.post" class="col-lg-4 col-md-6" data-testId="news-article-posts">
              <div class="post">
                <div class="cover">
                  <img :key="`img_${row.id}`" :src="row.featuredImage" :alt="row.featuredImageAltText" />
                </div>
                <div class="text-post">
                  <p class="date">{{ getFormattedDate(row.publishDate) }}</p>
                  <h3>
                    <a :href="row.url" target="_blank" data-testId="news-article-link">{{ row.name }}</a>
                  </h3>
                  <div class="short-text">
                    <p>{{ stripHtml(row.postBody) }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6" v-if="isLoading">
              <div class="post">
                <div class="shimmer-wrapper">
                  <div class="shine box"></div>
                  <div class="shim-container">
                    <div class="shine lines"></div>
                    <div class="shine lines"></div>
                    <div class="shine lines"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <div class="notFind">
        <h2>{{ $t("customer_dashboard.news_portal.no_data.title") }}</h2>
        <p>{{ $t("customer_dashboard.news_portal.no_data.subtitle") }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    postList() {
      let list = [];
      let uniqueYear = {};
      list = this.data.map(item => {
        let year = item.publishDate.split("-");
        if (!uniqueYear[year[0]]) {
          uniqueYear[year[0]] = true;

          let post = [];
          post = this.data.filter(row => {
            let postyear = row.publishDate.split("-");
            if (year[0] == postyear[0]) {
              return row;
            }
          });
          return { year: year[0], post };
        }
      });

      return list.filter(Boolean);
    },
  },
};
</script>
<style lang="scss" scoped>
ul,
li {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.bg {
  background: #a5b3c1;
  padding: 40px 0;
}
.content-fix {
  max-width: 1270px;
  margin: 0 auto;
}
.year-publication {
  color: #f2f4f6;
  font-weight: bold;
  font-size: 70px;
  position: relative;
  margin: 50px 0 50px 10px;
  &:before {
    content: "";
    position: absolute;
    height: 1px;
    width: calc(100% - 200px);
    background: #a5b3c1;
    top: 14px;
    left: 200px;
  }
}
.post {
  background: #fff;
  height: 400px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin: 10px;
  overflow: hidden;
  h3,
  p {
    margin: 0;
    padding: 0;
  }
  h3 a {
    text-decoration: none;
    color: #003349;
  }
  .cover {
    height: 152px;
    background: #ddd;
    overflow: hidden;
    margin-bottom: 1px;
  }
  .cover img {
    width: 100%;
  }
  p.date {
    color: #cb333b;
    font-weight: bold;
    font-size: 11px;
    display: block;
    margin: 3px 1px;
  }
  h3 {
    display: block;
    margin-bottom: 10px;
  }
}
.text-post {
  padding: 10px;
}
.notFind {
  color: #f2f4f6;
  font-size: 25px;
  line-height: 1.3;
}
.notFind p {
  font-size: 16px;
}
.shimmer-wrapper {
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;

  .shine {
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 300px;
    display: inline-block;
    position: relative;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
  }

  .box {
    height: 263px;
    min-width: 100%;
  }

  .shim-container {
    display: inline-flex;
    flex-direction: column;
    margin-left: 25px;
    margin-top: 15px;
    vertical-align: top;
  }

  .lines {
    height: 10px;
    margin-top: 10px;
    width: 200px;
  }

  .photo {
    display: block;
    width: 325px;
    height: 100px;
    margin-top: 15px;
  }
}
@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
</style>
